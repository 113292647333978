export const secondsToHms = (
  seconds = 0,
  guide = seconds,
  showMilliseconds = false
): string => {
  let s: any = Math.floor(seconds % 60);
  let m: any = Math.floor((seconds / 60) % 60);
  let h: any = Math.floor(seconds / 3600);
  let ms: any = Math.floor((seconds * 1000) % 1000);
  const gm = Math.floor((guide / 60) % 60);
  const gh = Math.floor(guide / 3600);
  const gms = Math.floor((guide * 1000) % 1000);

  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    h = '-';
    m = '-';
    s = '-';
    ms = '-';
  }

  // Check if we need to show hours
  h = h > 0 || gh > 0 ? `${h}:` : '';

  // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.
  m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;

  // Check if leading zero is need for seconds
  s = s < 10 ? `0${s}` : s;

  if (gms >= 0 && ms === 0) {
    ms = `000`;
  } else if (gms >= 0 && ms < 10) {
    ms = `00${ms}`;
  } else if (gms >= 0 && ms < 100) {
    ms = `0${ms}`;
  } else {
    ms = `${ms}`;
  }

  return h + m + s + (showMilliseconds ? `.${ms}` : '');
};
