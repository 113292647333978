import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import to from 'await-to-js';
import cx from 'classnames';
import styled from 'styled-components';
import { useMeasure } from 'react-use';
import {
  IThumbnailSize,
  getThumbnail,
  getTinyThumbWithOriginalExt,
  RequestCancellation,
} from '@voomly/utils';

const StyledPicture = styled.picture`
  display: block;

  &.fadeIn img {
    visibility: visible;
    opacity: 1;
    animation: fadein 150ms ease-in;
  }

  &.disableFadeIn img {
    visibility: visible;
    opacity: 1;
    animation: none;
  }

  img {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const originalWidthCache: Record<string, number> = {};

const useGetImageOriginalWidth = (url: string) => {
  const [isError, setIsError] = useState(false);
  const [originalWidth, setOriginalWith] = useState<number | undefined>(
    originalWidthCache[url]
  );

  // Update originalWidth if we already fetched it
  useEffect(() => {
    if (!originalWidthCache[url]) {
      return;
    }

    setOriginalWith(originalWidthCache[url]);
  }, [url]);

  useEffect(() => {
    if (originalWidthCache[url]) {
      // Skip if we already fetched the original width
      return;
    }

    const requestCancellation = new RequestCancellation();

    (async () => {
      const [err, response] = await to(
        axios.get(url, { cancelToken: requestCancellation.getCancelToken() })
      );

      if (err) {
        console.log('HTTP-Error: ' + response?.status);
        setIsError(true);
      }

      const metaImageSize = response?.headers['x-amz-meta-original-image-size'];

      if (metaImageSize) {
        const [width] = metaImageSize.split('x').map(Number);
        setOriginalWith(width);
        originalWidthCache[url] = width;
      } else {
        setIsError(true);
      }
    })();

    return () => {
      requestCancellation.cancelPreviousRequest();
    };
  }, [url]);

  return useMemo(
    () => [originalWidth, isError] as const,
    [originalWidth, isError]
  );
};

interface IOptimizedImageProps {
  className?: string;
  alt: string;
  url: string;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
  size: IThumbnailSize | 'auto';
}

const OptimizedImageRaster = ({
  className,
  alt,
  url,
  size,
  onLoad,
}: IOptimizedImageProps) => {
  const tinyThumbUrl = getTinyThumbWithOriginalExt(url);
  const [maxWidth, isError] = useGetImageOriginalWidth(tinyThumbUrl);
  const [ref, { width }] = useMeasure();

  const thumbnail = useMemo(() => {
    const thumbWidth = size === 'auto' ? width : size;

    return maxWidth ? getThumbnail(url, thumbWidth, maxWidth) : undefined;
  }, [size, width, maxWidth, url]);

  return (
    <StyledPicture className={className} ref={ref}>
      {thumbnail && !isError ? (
        <>
          <source
            srcSet={[
              `${thumbnail?.x1_webp} 1x`,
              thumbnail?.x2_webp ? `${thumbnail?.x2_webp} 2x` : undefined,
            ]
              .filter((x) => x)
              .join(', ')}
            type="image/webp"
          />
          <img
            onLoad={onLoad}
            src={thumbnail?.x1}
            srcSet={thumbnail?.x2 ? `${thumbnail?.x2} 2x` : undefined}
            alt={alt}
          />
        </>
      ) : (
        <img onLoad={onLoad} src={url} alt={alt} />
      )}
    </StyledPicture>
  );
};

const OptimizedImageVector = ({
  className,
  url,
  alt,
  onLoad,
}: IOptimizedImageProps) => {
  return (
    <StyledPicture className={className}>
      <img onLoad={onLoad} src={url} alt={alt} />
    </StyledPicture>
  );
};

export const OptimizedImage = memo(
  ({
    disableFadeIn,
    ...props
  }: Omit<IOptimizedImageProps, 'onLoad'> & { disableFadeIn?: boolean }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const handleLoaded = useCallback(() => setIsImageLoaded(true), []);
    const isVector = props.url.endsWith('.svg');

    const className = cx(props.className, {
      fadeIn: isImageLoaded,
      disableFadeIn,
    });

    return isVector ? (
      <OptimizedImageVector
        {...props}
        className={className}
        onLoad={handleLoaded}
      />
    ) : (
      <OptimizedImageRaster
        {...props}
        className={className}
        onLoad={handleLoaded}
      />
    );
  }
);
