import React, { memo, useCallback, useEffect, useRef } from 'react';
import fscreen from 'fscreen';
import cx from 'classnames';
import styles from './styles.module.sass';

type INativeFullScreenProps = {
  children: React.ReactNode;
  enabled: boolean;
  onChange: (isFullscreen: boolean) => void;
};

const fullscreenStyle = { height: '100%', width: '100%' };

const NativeFullScreenComponent = ({
  children,
  enabled,
  onChange,
}: INativeFullScreenProps) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const fullScreenElementRef = useRef<Element | null>(null);

  const enterFullScreen = useCallback(() => {
    if (fscreen.fullscreenEnabled && nodeRef.current) {
      fscreen.requestFullscreen(nodeRef.current);
    }
  }, []);

  const leaveFullScreen = useCallback(() => {
    if (fscreen.fullscreenEnabled) {
      fscreen.exitFullscreen();
    }
  }, []);

  const handleProps = useCallback(
    (props: INativeFullScreenProps) => {
      const enabled = fscreen.fullscreenElement === nodeRef.current;
      if (enabled && !props.enabled) {
        leaveFullScreen();
      } else if (!enabled && props.enabled) {
        enterFullScreen();
      }
    },
    [enterFullScreen, leaveFullScreen]
  );

  const detectFullScreen = useCallback(() => {
    if (fscreen.fullscreenElement === nodeRef.current) {
      fullScreenElementRef.current = fscreen.fullscreenElement;
      onChange(true);
    } else if (!fscreen.fullscreenElement && fullScreenElementRef.current) {
      fullScreenElementRef.current = null;
      onChange(false);
    }
  }, [onChange]);

  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', detectFullScreen);

    return () => {
      fscreen.removeEventListener('fullscreenchange', detectFullScreen);
    };
  }, [detectFullScreen]);

  useEffect(() => {
    handleProps({
      children,
      enabled,
      onChange,
    });
  }, [children, enabled, onChange, handleProps]);

  return (
    <div
      className={cx(styles.fullscreen, { [styles.enabled]: enabled })}
      ref={nodeRef}
      style={enabled ? fullscreenStyle : undefined}
    >
      {children}
    </div>
  );
};

export const NativeFullScreen = memo(NativeFullScreenComponent);
