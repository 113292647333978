import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useReducer,
  useState,
} from 'react';
import { assertUnreachable, omit } from '@voomly/utils';

interface IIFsFileItemCustomSubtitlesItem {
  id: string;
  startTime: number;
  endTime: number;
  content: string;
}

type Actions =
  | {
      type: 'add';
      payload: IIFsFileItemCustomSubtitlesItem & { insertAfterId?: string };
    }
  | { type: 'init'; payload: IIFsFileItemCustomSubtitlesItem[] }
  | {
      type: 'update';
      payload: { id: string } & Partial<IIFsFileItemCustomSubtitlesItem>;
    }
  | { type: 'delete'; payload: string }
  | { type: 'reset'; payload?: [] };

export const EditableSubtitlesIdContext = createContext<string | undefined>(
  undefined
);

export const EditableSubtitlesSetIdContext = createContext<
  React.Dispatch<React.SetStateAction<string | undefined>>
>(() => {});

export const EditableSubtitlesIsDirtyContext = createContext<boolean>(false);

export const EditableSubtitlesSetIsDirtyContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});

export const EditableSubtitlesSetIsEnabledContext = createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => {});

export const EditableSubtitlesItemsContext = createContext<
  IIFsFileItemCustomSubtitlesItem[]
>([]);

export const EditableSubtitlesItemsDispatchContext = createContext<
  React.Dispatch<Actions>
>(() => {});

export const EditableSubtitlesContextProvider = memo(({ children }) => {
  const [editableSubtitlesItems, dispatch] = useReducer(
    editableSubtitlesItemsReducer,
    initialEditableSubtitlesItems
  );
  const [editableSubtitlesIsDirty, setEditableSubtitlesIsDirty] =
    useState(false);
  const [editableSubtitlesId, setEditableSubtitlesId] = useState<
    string | undefined
  >(undefined);

  return (
    <EditableSubtitlesIdContext.Provider value={editableSubtitlesId}>
      <EditableSubtitlesSetIdContext.Provider value={setEditableSubtitlesId}>
        <EditableSubtitlesIsDirtyContext.Provider
          value={editableSubtitlesIsDirty}
        >
          <EditableSubtitlesSetIsDirtyContext.Provider
            value={setEditableSubtitlesIsDirty}
          >
            <EditableSubtitlesItemsContext.Provider
              value={editableSubtitlesItems}
            >
              <EditableSubtitlesItemsDispatchContext.Provider value={dispatch}>
                {children}
              </EditableSubtitlesItemsDispatchContext.Provider>
            </EditableSubtitlesItemsContext.Provider>
          </EditableSubtitlesSetIsDirtyContext.Provider>
        </EditableSubtitlesIsDirtyContext.Provider>
      </EditableSubtitlesSetIdContext.Provider>
    </EditableSubtitlesIdContext.Provider>
  );
});

const editableSubtitlesItemsReducer = (
  state: IIFsFileItemCustomSubtitlesItem[],
  { type, payload }: Actions
) => {
  switch (type) {
    case 'init':
      return payload;
    case 'add':
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index),
      ];
      const insertAfterIndex = state.findIndex(
        (item) => payload.insertAfterId === item.id
      );
      const subtitlesItem: IIFsFileItemCustomSubtitlesItem = omit(payload, [
        'insertAfterId',
      ]);
      return insert(
        state,
        payload.insertAfterId ? insertAfterIndex + 1 : 0,
        subtitlesItem
      );
    case 'update':
      return state.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    case 'delete':
      return state.filter((item) => item.id !== payload);
    case 'reset':
      return [];
    default: {
      assertUnreachable(type);
    }
  }

  return state;
};

const initialEditableSubtitlesItems: IIFsFileItemCustomSubtitlesItem[] = [];

export const useEditableSubtitlesItems = () =>
  useContext(EditableSubtitlesItemsContext);

export const useEditableSubtitlesItemsDispatch = () =>
  useContext(EditableSubtitlesItemsDispatchContext);

export const useEditableSubtitlesIsDirty = () =>
  useContext(EditableSubtitlesIsDirtyContext);

export const useEditableSubtitlesSetIsDirty = () =>
  useContext(EditableSubtitlesSetIsDirtyContext);

export const useEditableSubtitlesId = () =>
  useContext(EditableSubtitlesIdContext);

export const useEditableSubtitlesSetId = () =>
  useContext(EditableSubtitlesSetIdContext);

export const useEditableSubtitlesReset = () => {
  const editableSubtitlesItemsDispatch = useEditableSubtitlesItemsDispatch();
  const editableSubtitlesSetId = useEditableSubtitlesSetId();
  const editableSubtitlesSetIsDirty = useEditableSubtitlesSetIsDirty();

  return useCallback(() => {
    editableSubtitlesItemsDispatch({
      type: 'reset',
    });
    editableSubtitlesSetId(undefined);
    editableSubtitlesSetIsDirty(false);
  }, [
    editableSubtitlesItemsDispatch,
    editableSubtitlesSetId,
    editableSubtitlesSetIsDirty,
  ]);
};
