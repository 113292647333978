import { ITimelineShareGate, ITimelineTurnstile } from '../../types/types';
import store from 'store';

export const getTurnstileShowStorageKey = (item: ITimelineTurnstile) =>
  `turnstile-${item.id}`;
export const getIsTurnstileEmailCollectedStorageKey = (
  item: ITimelineTurnstile
) => `turnstile-${item.id}-email-collected`;

const checkIfShouldShowAfterPeriod = (
  lastShowDateValue: string,
  showFrequency: 'everyDay' | 'everyWeek'
) => {
  const lastShowDate = Date.parse(lastShowDateValue);

  const nowMinusDay = new Date();
  nowMinusDay.setDate(nowMinusDay.getDate() - 1);

  // If we've shown it that day
  if (showFrequency === 'everyDay' && nowMinusDay.getTime() > lastShowDate) {
    return true;
  }

  const nowMinusWeek = new Date();
  nowMinusWeek.setDate(nowMinusWeek.getDate() - 7);

  // If we've shown it that week
  if (showFrequency === 'everyWeek' && nowMinusWeek.getTime() > lastShowDate) {
    return true;
  }
};

export const shouldShowTurnstile = (item: ITimelineTurnstile) => {
  // If we collected email
  const emailCollectedKey = getIsTurnstileEmailCollectedStorageKey(item);
  const isEmailCollected = store.get(emailCollectedKey);
  if (isEmailCollected && item.showFrequency === 'untilEmailCollected') {
    return false;
  }

  // If we've shown it once
  const storageKey = getTurnstileShowStorageKey(item);
  const lastShowDateValue = store.get(storageKey);
  if (lastShowDateValue && item.showFrequency === 'once') {
    return !item.skippable ? !isEmailCollected : false;
  }

  // If we should always show it
  if (!lastShowDateValue || item.showFrequency === 'always') {
    return true;
  }

  // If we've shown it that day/week
  if (item.showFrequency === 'everyWeek' || item.showFrequency === 'everyDay') {
    return checkIfShouldShowAfterPeriod(lastShowDateValue, item.showFrequency);
  }

  // otherwise show it
  return true;
};

export const getShareGateShowStorageKey = (item: ITimelineShareGate) =>
  `shareGate-${item.id}`;

export const shouldShowShareGate = (item: ITimelineShareGate) => {
  // If we've shown it once
  const storageKey = getShareGateShowStorageKey(item);
  const lastShowDateValue = store.get(storageKey);
  if (lastShowDateValue && item.showFrequency === 'once') {
    return false;
  }

  // If we should always show it
  if (!lastShowDateValue || item.showFrequency === 'always') {
    return true;
  }

  // If we've shown it that day/week
  if (item.showFrequency === 'everyWeek' || item.showFrequency === 'everyDay') {
    return checkIfShouldShowAfterPeriod(lastShowDateValue, item.showFrequency);
  }

  // otherwise show it
  return true;
};
