import React, { memo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { mergeRefs } from '@voomly/utils';
import { findBorderSkin } from '../../store/video/borderSkins/borderSkins';
import stylesLayout from '../VideoLayout/videoLayout.module.sass';
import { actions } from '../../store/containerDimensions';
import {
  selectContainerHeight,
  selectContainerWidth,
  selectIsFullScreen,
} from '../../store/containerDimensions/selectors';
import { IPlayerTemplate } from '../../types/player';
import { useMouseHandlers } from '../../contexts/MouseMoveContext';
import { usePlayerKeybindings } from '../../hooks/usePlayerKeybindings';
import { useResizableRatio } from '../../hooks/useResizableRatio';
import { Border } from './Border/Border';
import styles from './borderSkin.module.sass';

const MainContainerWithBorderSkinComponent = ({
  innerRef,
  videoRatio,
  player,
  children,
  extraContent,
  onMouseDown,
  onTouchStart,
}: {
  innerRef: React.RefObject<HTMLDivElement>;
  videoRatio: number;
  player: IPlayerTemplate;
  children: React.ReactNode;
  extraContent: React.ReactNode;
} & ReturnType<typeof usePlayerKeybindings>) => {
  const {
    skin: { borderSkinEnabled, borderSkin: borderSkinKey },
  } = player;
  const dispatch = useDispatch();
  const mouseHandlers = useMouseHandlers();
  const isFullScreen = useSelector(selectIsFullScreen);
  const containerWidth = useSelector(selectContainerWidth);
  const containerHeight = useSelector(selectContainerHeight);
  const borderSkin = findBorderSkin(borderSkinKey);
  const ratio = borderSkinEnabled
    ? borderSkin.width / borderSkin.height
    : videoRatio;
  const [ref, { width, height }, isReady] = useResizableRatio({
    name: 'container',
    ratio,
  });

  useLayoutEffect(() => {
    dispatch(actions.setContainerDimensions({ width, height }));
  }, [dispatch, width, height, borderSkinEnabled]);

  const finalClassName = cx(styles.root, styles[borderSkinKey]);
  const shouldRender = !isFullScreen && borderSkinEnabled;

  const props = {
    key: 'CONTAINER',
    ref: mergeRefs(ref, innerRef),
    className: cx(stylesLayout.root, { [stylesLayout.ready]: isReady }),
    style: { width: containerWidth, height: containerHeight },
    onMouseDown,
    onTouchStart,
    ...mouseHandlers,
  };

  if (!borderSkinEnabled) {
    return (
      <div {...props}>
        {extraContent}
        {children}
      </div>
    );
  }

  return (
    <div {...props}>
      {extraContent}
      <Border
        {...borderSkin}
        className={cx(styles.withSkin, finalClassName)}
        shouldRender={shouldRender}
        key="inner"
      >
        {children}
      </Border>
    </div>
  );
};

export const BorderSkinLayout = memo(MainContainerWithBorderSkinComponent);
