import axios, { CancelToken } from 'axios';
import {
  IEmbedPlayerData,
  IPasswordProtectedEmbedPlayerData,
} from '../types/embedVideo';
import { makeUrl } from '@voomly/utils';
import { IPlayerVideo } from '../types/video';

export type IEmbedVideoPublicResponse =
  | IEmbedPlayerData
  | IPasswordProtectedEmbedPlayerData;

export const getEmbedVideoPublicRequest = (embedVideoId: string) =>
  axios.get<IEmbedVideoPublicResponse>(
    makeUrl(`/embed-videos/${embedVideoId}`)
  );

export const isEmbedVideoPasswordProtected = (
  value: IEmbedVideoPublicResponse
): value is IPasswordProtectedEmbedPlayerData => {
  return !!value.player.security?.enabled;
};

export const getPublicFileRequest = (id: string, cancelToken?: CancelToken) =>
  axios.get<IPlayerVideo>(makeUrl(`/videos/${id}/voomly`), { cancelToken });

export const getPasswordProtectedEmbedVideoPublicRequest = (
  embedVideoId: string,
  password: string
) =>
  axios.post<IEmbedPlayerData>(makeUrl(`/embed-videos/${embedVideoId}/get`), {
    password,
  });
