import React from 'react';
import HlsJs, { HlsConfig, ErrorData } from 'hls.js/dist/hls.light.js';
import * as Sentry from '@sentry/browser';

const HLS_MAX_MAX_BUFFER_LENGTH = 10; // Max number of seconds to load to buffer

interface IProps {
  url: string;
  load: boolean;
  quality: number;
  startPosition?: number;
  onError?: (errorData: string | ErrorData) => void;
  onReady?: () => void;
  children: ({
    registerVideoRef,
  }: {
    registerVideoRef: (video: HTMLVideoElement | null | undefined) => void;
  }) => React.ReactNode;
}

export class Hls extends React.PureComponent<IProps> {
  static defaultProps = {
    load: false,
    quality: -1,
  };

  private hls: HlsJs | undefined = undefined;
  private video?: HTMLVideoElement | null;
  // contains the last estimated bandwidth by hls.js
  private lastEstimatedBandwidth?: number | null;

  manifestParsed = false;
  loadOnManifestParsed = false;

  componentDidMount() {
    // @ts-ignore
    window.hlsComponent = this;

    this.updateHlsUrl();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.url !== this.props.url) {
      /* try to extract bandwidthEstimate from the previous hls.js instance
       * we use it to start the next video using the last estimated bandwidth
       * to start the video with suitable quality
       */
      const prevBandwidthEstimate = this.hls?.bandwidthEstimate;

      if (
        prevBandwidthEstimate &&
        prevBandwidthEstimate !== this.lastEstimatedBandwidth
      ) {
        console.debug('prevBandwidthEstimate:', prevBandwidthEstimate);

        this.lastEstimatedBandwidth = Math.floor(prevBandwidthEstimate);
      } else {
        this.lastEstimatedBandwidth = null;
      }

      this.hls?.destroy();
      this.updateHlsUrl();
    }

    if (this.hls && prevProps.quality !== this.props.quality) {
      this.hls.currentLevel =
        this.props.quality === -1
          ? -1
          : this.hls.levels.length - this.props.quality - 1;
    }

    if (this.hls && this.props.load && prevProps.load !== this.props.load) {
      if (this.manifestParsed) {
        this.hls.startLoad();
      } else {
        this.loadOnManifestParsed = true;
      }
    }
  }

  componentWillUnmount() {
    if (this.hls) {
      this.hls.destroy();
    }
  }

  private updateHlsUrl() {
    const { url, load, onError, onReady } = this.props;
    const videoEl = this.video;

    if (!videoEl) {
      return;
    }

    if (!HlsJs.isSupported() || !url) {
      if (onError) onError('HLS is not supported by the browser');
      return;
    }

    const params: Partial<HlsConfig> = {
      autoStartLoad: load,
      capLevelToPlayerSize: true,
      debug: false,
      maxMaxBufferLength: HLS_MAX_MAX_BUFFER_LENGTH,
      startPosition: this.props.startPosition,
      startLevel: -1, // start from the lowest quality
    };

    if (this.lastEstimatedBandwidth) {
      console.debug(
        'Use defaultBandwidthEstimate',
        this.lastEstimatedBandwidth
      );

      // set default precalculated bandwidth to say hls.js which quality it has to choose
      params.abrEwmaDefaultEstimate = this.lastEstimatedBandwidth;
      // if we provide abrEwmaDefaultEstimate we have to disable testBandwidth otherwise hls.js will ignore abrEwmaDefaultEstimate
      params.testBandwidth = false;
    }

    console.debug('HLS init params: ', params);

    this.hls = new HlsJs(params);

    const safeHls = this.hls;
    safeHls.attachMedia(videoEl);
    safeHls.on(HlsJs.Events.MEDIA_ATTACHED, () => {
      // <video> and hls.js are now bound together
      safeHls.loadSource(url);

      safeHls.on(HlsJs.Events.MANIFEST_PARSED, () => {
        this.manifestParsed = true;
        onReady?.();
        // if (this.loadOnManifestParsed) {
        //   this.loadOnManifestParsed = false;
        //   this.hls!.startLoad();
        // }
      });
    });

    // this.manifestParsed = false;

    safeHls.on(HlsJs.Events.ERROR, (event, data) => {
      const errorType = data.type;
      const errorDetails = data.details;
      const errorFatal = data.fatal;

      if (errorFatal) {
        switch (errorType) {
          case HlsJs.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            // fatal network error encountered, try to recover
            safeHls.startLoad();
            break;
          case HlsJs.ErrorTypes.MEDIA_ERROR:
            // fatal media error encountered, try to recover
            safeHls.recoverMediaError();
            break;
          default:
            // cannot recover
            safeHls.destroy();
            if (onError) onError(data);
            break;
        }
      } else {
        // non fatal error, safe to ignore
        console.error('player error, but continue', data);

        if (data.details === 'internalException') {
          Sentry.captureException(data);
        }
      }
      console.log({ errorType, errorDetails, errorFatal });
    });
  }

  registerVideoRef = (node: HTMLVideoElement | null | undefined) => {
    this.video = node;
  };

  render() {
    const { children } = this.props;

    return children({
      registerVideoRef: this.registerVideoRef,
    });
  }
}
