import * as React from 'react';
import { findDOMNode } from 'react-dom';
import cx from 'classnames';
import { getPointerPosition } from './utils';
import styles from './slider.module.sass';

interface Props {
  className?: any;
  style?: any;
  vertical?: boolean;
  onMouseMove?: (p: any, e: any) => void;
  onMouseDown?: (p: any, e: any) => void;
  onMouseUp?: (p: any, e: any) => void;
  onLocalMouseMove?: (
    position: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onLocalMouseLeave?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  children: React.ReactNode;
}

class Slider extends React.Component<Props> {
  state = {
    active: false,
  };

  componentWillUnmount = () => {
    this.removeEventListeners();
  };

  removeEventListeners() {
    document.removeEventListener('mousemove', this.handleMouseMove, true);
    document.removeEventListener('mouseup', this.handleMouseUp, true);
    document.removeEventListener('touchmove', this.handleMouseMove, true);
    document.removeEventListener('touchend', this.handleMouseUp, true);
  }

  getPosition(event) {
    const node = findDOMNode(this);
    const position = getPointerPosition(node, event);

    return this.props.vertical ? position.y : position.x;
  }

  handleMouseMove = (event) => {
    const { onMouseMove } = this.props;

    if (!onMouseMove) {
      return;
    }

    const position = this.getPosition(event);
    onMouseMove(position, event);
  };

  handleMouseDown = (event) => {
    const { onMouseDown } = this.props;

    document.addEventListener('mousemove', this.handleMouseMove, true);
    document.addEventListener('mouseup', this.handleMouseUp, true);
    document.addEventListener('touchmove', this.handleMouseMove, true);
    document.addEventListener('touchend', this.handleMouseUp, true);

    this.setState({
      active: true,
    });

    this.handleMouseMove(event);

    if (!onMouseDown) {
      return;
    }

    const position = this.getPosition(event);
    onMouseDown(position, event);
  };

  handleMouseUp = (event) => {
    const { onMouseUp } = this.props;

    this.removeEventListeners();

    this.setState({
      active: false,
    });

    if (!onMouseUp) {
      return;
    }

    const position = this.getPosition(event);
    onMouseUp(position, event);
  };

  handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  handleLocalMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { onLocalMouseMove } = this.props;

    if (!onLocalMouseMove) return;

    const position = this.getPosition(event);
    onLocalMouseMove(position, event);
  };

  handleLocalMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { onLocalMouseLeave } = this.props;

    if (!onLocalMouseLeave) return;

    onLocalMouseLeave(event);
  };

  render() {
    const { children, className, style } = this.props;

    return (
      <div
        tabIndex={0}
        onMouseMove={this.handleLocalMouseMove}
        onMouseLeave={this.handleLocalMouseLeave}
        onMouseDown={this.handleMouseDown}
        onTouchStart={this.handleMouseDown}
        onClick={this.handleClick}
        className={cx(className, styles.slider, {
          vertical: this.props.vertical,
          sliding: this.state.active,
        })}
        style={style}
      >
        {children}
      </div>
    );
  }
}

export default Slider;
