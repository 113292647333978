import { Channel } from 'redux-saga';
import { createStandardAction } from 'typesafe-actions';
import { IMouseEvent } from './controlVisibilitySaga';
import { PlayerMode } from '../../components/types/defaultPropTypes';

export type IControlsSelects =
  | 'SELECT_SPEED'
  | 'SELECT_QUALITY'
  | 'SELECT_SUBTITLES';

export const addControlsStayVisibleBy = createStandardAction(
  'videoControls/ADD_CONTROLS_STAY_VISIBLE_BY'
)<IControlsSelects>();
export const removeControlsStayVisibleBy = createStandardAction(
  'videoControls/REMOVE_CONTROLS_STAY_VISIBLE_BY'
)<IControlsSelects>();
export const showControls = createStandardAction(
  'videoControls/SHOW_CONTROLS'
)();
export const hideControls = createStandardAction(
  'videoControls/HIDE_CONTROLS'
)();
export const changeClickForSoundVisibility = createStandardAction(
  'videoControls/CHANGE_CLICK_FOR_SOUND_VISIBILITY'
)<{ isVisible: boolean }>();

export const changeLogoVisibility = createStandardAction(
  'videoControls/CHANGE_LOGO_VISIBILITY'
)<{ isVisible: boolean }>();

export const registerMouseChannel = createStandardAction(
  'videoControls/REGISTER_MOUSE_CHANNEL'
)<{ ch: Channel<IMouseEvent> | undefined; playerMode: PlayerMode }>();

export const enableShortcuts = createStandardAction(
  'videoControls/ENABLE_SHORTCUTS'
)();

export const disableShortcuts = createStandardAction(
  'videoControls/DISABLE_SHORTCUTS'
)();
