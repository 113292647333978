import { IPlayerRootReducerShape } from '../rootPlayerStore';
import { HIGHLIGHT_NOTHING_HIDE_EVERYTHING } from '../videoItems/constants';
import { ITimelineItem } from '../../types/types';
import {
  getItemsOnScreen,
  getIsBackgroundDimmed,
} from '../videoItems/selectors';
import { getIsPlaybackBlocked } from '../selectors';

export const isClickForSoundVisibleSelector = (
  state: IPlayerRootReducerShape
) => state.videoControls.isClickForSoundVisible;

export const getAreControlsVisiblePure = (state: IPlayerRootReducerShape) =>
  state.videoControls.areControlsVisible ||
  state.videoControls.controlsStayVisibleBy.length > 0;

export const getAreControlsVisible = (state: IPlayerRootReducerShape) => {
  const dimmedControlsHide =
    !state.videoState.paused && getIsBackgroundDimmed(state);

  const alternativeDurationLineHide = Boolean(
    state.miscScreenObjects.alternativeDurationLine
  );

  return (
    getAreControlsVisiblePure(state) &&
    !dimmedControlsHide &&
    !alternativeDurationLineHide &&
    !getIsPlaybackBlocked(state)
  );
};

const showCursorIfObjectTypeScreen: ITimelineItem['type'][] = [
  'button',
  'grid',
  'survey',
  'shareGate',
  'turnstile',
  'email',
];

export const getIsCursorVisible = (state: IPlayerRootReducerShape) => {
  if (!state.videoState.isPlayRequested) return true;

  // loading next video, fixes the bug of disappearing mouse when clicked on a
  // button which leads to another video while video is playing
  const { highlightedItemId } = state.videoItemsOnScreen;

  const itemsOnScreen = getItemsOnScreen(state);

  const player = state.sourceConfiguration?.current?.playerConfig;
  if (player) {
    if (
      itemsOnScreen.some((item) => {
        return Boolean(showCursorIfObjectTypeScreen.includes(item.type));
      })
    ) {
      return true;
    }
  }
  if (
    highlightedItemId &&
    highlightedItemId !== HIGHLIGHT_NOTHING_HIDE_EVERYTHING
  ) {
    return true;
  }

  return getAreControlsVisiblePure(state);
};

export const getAreShortcutsDisabled = (state: IPlayerRootReducerShape) =>
  state.videoControls.areShortcutsDisabled;
