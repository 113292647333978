import axios, { CancelTokenSource } from 'axios';
const { CancelToken } = axios;

// Usage: call `cancelPreviousRequest` in the very beginning of cancellable API,
// and pass getCancelToken() to `cancelToken` of axios request
export class RequestCancellation {
  private lastAttempt: CancelTokenSource | undefined = undefined;
  public cancelPreviousRequest() {
    if (this.lastAttempt) {
      this.lastAttempt.cancel();
      this.lastAttempt = undefined;
    }
  }
  public getCancelToken() {
    this.lastAttempt = CancelToken.source();
    return this.lastAttempt.token;
  }

  public isCancel(err: any) {
    return axios.isCancel(err);
  }
}
