import { throttle } from 'lodash-es';
import * as React from 'react';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Channel, channel } from 'redux-saga';
import { registerMouseChannel } from '../store/videoControls/actions';
import { IMouseEvent } from '../store/videoControls/controlVisibilitySaga';
import { PlayerMode } from '../components/types/defaultPropTypes';

const MouseChannelContext = createContext<Channel<IMouseEvent> | undefined>(
  undefined
);

type IMouseChannelProviderProps = {
  children: React.ReactNode;
  playerMode: PlayerMode;
};

export const MouseChannelProvider: React.FC<IMouseChannelProviderProps> = ({
  children,
  playerMode,
}) => {
  const dispatch = useDispatch();
  const chRef = useRef(channel<IMouseEvent>());

  useEffect(() => {
    dispatch(registerMouseChannel({ ch: chRef.current, playerMode }));

    return () => {
      dispatch(registerMouseChannel({ ch: undefined, playerMode }));
    };
  }, [dispatch, playerMode]);

  return (
    <MouseChannelContext.Provider value={chRef.current}>
      {children}
    </MouseChannelContext.Provider>
  );
};

export const useMouseHandlers = (initiator?: IMouseEvent['initiatedBy']) => {
  const ch = useContext(MouseChannelContext);

  if (!ch) throw new Error('MouseChannelContext value is not present');

  const throttledMove = throttle(
    () => ch.put({ type: 'move', initiatedBy: initiator }),
    100
  );

  return useMemo(() => {
    return {
      onMouseMove: throttledMove,
      onClick: (evt) => {
        if (evt.detail === 2) {
          ch.put({ type: 'doubleClick', initiatedBy: initiator });
        }
      },
      onMouseLeave: () => {
        throttledMove.cancel();
        ch.put({ type: 'leave', initiatedBy: initiator });
      },
      onMouseEnter: () => {
        ch.put({ type: 'enter', initiatedBy: initiator });
      },
    };
  }, [ch, initiator, throttledMove]);
};
