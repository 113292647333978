// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_simulatedFullScreenFix__2zKk6{z-index:9999999999 !important;position:static !important;display:block !important}.styles_fullscreenSimulated__1Ue5w.styles_enabled__2msqt{-webkit-user-select:none;-moz-user-select:none;user-select:none}.styles_fullscreen__1exWu{display:flex;align-items:center;justify-content:center;width:100%;height:100%;overflow:hidden;position:relative}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/WithFullScreen/styles.module.sass"],"names":[],"mappings":"AAAA,sCACE,6BAAA,CACA,0BAAA,CACA,wBAAA,CAIA,yDACE,wBAAA,CAAA,qBAAA,CAAA,gBAAA,CAEJ,0BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".simulatedFullScreenFix\n  z-index: 9999999999 !important\n  position: static !important\n  display: block !important\n\n// Don't remove it, we use it for query selectors\n.fullscreenSimulated\n  &.enabled\n    user-select: none\n\n.fullscreen\n  display: flex\n  align-items: center\n  justify-content: center\n  width: 100%\n  height: 100%\n  overflow: hidden\n  position: relative\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simulatedFullScreenFix": "styles_simulatedFullScreenFix__2zKk6",
	"fullscreenSimulated": "styles_fullscreenSimulated__1Ue5w",
	"enabled": "styles_enabled__2msqt",
	"fullscreen": "styles_fullscreen__1exWu"
};
export default ___CSS_LOADER_EXPORT___;
