import * as React from 'react';
import {
  ShareGateItemBase,
  IShareGateItemBaseProps,
  IShareGateTarget,
} from './BaseShareGate';
import { windowOpen } from '@voomly/utils';
import store from 'store';
import { getShareGateShowStorageKey } from '../../../store/videoItems/utils';

interface IState {
  clickedOnShareButton: boolean;
}

export class PlayerShareGateItem extends React.PureComponent<
  IShareGateItemBaseProps,
  IState
> {
  public state: IState = {
    clickedOnShareButton: false,
  };

  private switchToContinueButtonTimeout?: number;

  public componentWillUnmount() {
    if (this.switchToContinueButtonTimeout) {
      window.clearTimeout(this.switchToContinueButtonTimeout);
    }
  }

  private handleContinueAfterShareClick = () => {
    store.set(
      getShareGateShowStorageKey(this.props.item),
      new Date().toDateString()
    );
    this.props.onDismiss(this.props.item);
  };

  private handleBackFromSharingClick = () =>
    this.setState({ clickedOnShareButton: false });

  private handleShareButtonClick = (target: IShareGateTarget) => {
    const SHARE_TIME = 5000;

    windowOpen(
      target.getUrl(
        encodeURIComponent(
          window.location.href.replace('app.voomly.com', 'share.voomly.com')
        )
      ),
      undefined,
      () => ({})
    );
    this.switchToContinueButtonTimeout = window.setTimeout(() => {
      this.setState({
        clickedOnShareButton: true,
      });
    }, SHARE_TIME);
  };

  public render() {
    const { clickedOnShareButton } = this.state;
    const { item } = this.props;

    return (
      <ShareGateItemBase
        doneSharingScreen={clickedOnShareButton}
        onBackFromSharingClick={this.handleBackFromSharingClick}
        onShareButtonClick={this.handleShareButtonClick}
        onContinueAfterSharingClick={this.handleContinueAfterShareClick}
        titleText={item.titleText}
        subTitleText={item.subTitleText}
        {...this.props}
      />
    );
  }
}
