// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slider_slider__36ilu{flex:1 1;margin:0 8px;position:relative;display:flex;align-items:center;height:100%;width:100%;outline:0;cursor:pointer;padding:0}.slider_slider__36ilu:hover .slider_handle__2USog{opacity:1;transition:opacity .1s cubic-bezier(0, 0, 0.2, 1)}.slider_slider__36ilu:hover .slider_track__3RIF8{height:calc(100% + 2px)}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Slider/slider.module.sass"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,YAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,SAAA,CACA,cAAA,CACA,SAAA,CAEE,kDACE,SAAA,CACA,iDAAA,CACF,iDACE,uBAAA","sourcesContent":[".slider\n  flex: 1\n  margin: 0 8px\n  position: relative\n  display: flex\n  align-items: center\n  height: 100%\n  width: 100%\n  outline: 0\n  cursor: pointer\n  padding: 0\n  &:hover\n    .handle\n      opacity: 1\n      transition: opacity .1s cubic-bezier(0.0,0.0,0.2,1)\n    .track\n      height: calc(100% + 2px)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": "slider_slider__36ilu",
	"handle": "slider_handle__2USog",
	"track": "slider_track__3RIF8"
};
export default ___CSS_LOADER_EXPORT___;
