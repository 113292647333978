// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_selectSpeedWrap__2GzGK{position:relative}.styles_selectSpeedWrap__2GzGK:last-child .styles_selectSpeedDropdown__2wu61>div{left:0}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/SelectSpeed/styles.module.sass"],"names":[],"mappings":"AAAA,+BACE,iBAAA,CAGI,iFACE,MAAA","sourcesContent":[".selectSpeedWrap\n  position: relative\n  &:last-child\n    .selectSpeedDropdown\n      > div\n        left: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectSpeedWrap": "styles_selectSpeedWrap__2GzGK",
	"selectSpeedDropdown": "styles_selectSpeedDropdown__2wu61"
};
export default ___CSS_LOADER_EXPORT___;
