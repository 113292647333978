import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';
import { IPlayerRootReducerShape } from '../../store/rootPlayerStore';
import styled, { css } from 'styled-components';
import { setIsFullScreen } from '../../store/containerDimensions/actions';
import { useInterval, usePrevious } from 'react-use';
import cx from 'classnames';
import fscreen from 'fscreen';
import styles from './styles.module.sass';
import { NativeFullScreen } from './NativeFullScreen';

const fullScreenId = 'voomly-fullscreen-simulated-style';

const isFullScreenApiAvailable = fscreen.fullscreenEnabled;

const WithFullScreen: React.FC = ({ children }) => {
  if (isFullScreenApiAvailable) {
    return <WithFullScreenAPI children={children} />;
  } else {
    return <SimulatedFullScreen children={children} />;
  }
};

const WithFullScreenAPI: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const setFullscreen = useCallback(
    (value: boolean) => {
      dispatch(setIsFullScreen(value));
    },
    [dispatch]
  );
  const isFullScreen = useSelector(
    (state: IPlayerRootReducerShape) => state.dimensions.isFullScreen
  );

  return (
    <NativeFullScreen enabled={isFullScreen} onChange={setFullscreen}>
      {children}
    </NativeFullScreen>
  );
};
const SimulatedFullScreenStyled = styled.div<{
  enabled: boolean;
  screenHeight: number;
}>`
  ${({ enabled, screenHeight }) =>
    enabled &&
    css`
      position: fixed;
      max-width: 100vw;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: #000;
      height: ${screenHeight}px;
      transform: translateZ(1000px);
      z-index: 9999999999 !important;
    `}

  ${({ enabled }) =>
    !enabled &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    `}
`;

const SimulatedFullScreen: React.FC = ({ children }) => {
  const elemRef = useRef<HTMLDivElement | null>(null);

  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const isFullScreen = useSelector(
    (state: IPlayerRootReducerShape) => state.dimensions.isFullScreen
  );
  const prevIsFullScreen = usePrevious(isFullScreen);
  // We also need to restore prev scroll position, when user exit full screen
  const [scrollPosition, setScrollPosition] = React.useState<null | number>(
    null
  );

  useInterval(
    () => {
      if (screenHeight !== window.innerHeight) {
        setScreenHeight(window.innerHeight);
      }
    },
    isFullScreen ? 250 : null
  );

  // Is needed to make left and right strip black color
  // when iphone X is in a landscape orientation
  // and also to disable buggy zoom(but user can still zoom the player)
  // overflow hidden also disables not needed scroll

  // In case of bug can break our clients sites
  React.useEffect(() => {
    const styleTag = (() => {
      const existingStyleTag = document.getElementById(fullScreenId);

      if (existingStyleTag) {
        return existingStyleTag;
      }

      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.setAttribute('id', fullScreenId);
      style.type = 'text/css';

      head.appendChild(style);

      return style;
    })();

    styleTag.childNodes.forEach((e) => e.remove());

    if (isFullScreen) {
      if (!prevIsFullScreen) {
        setScrollPosition(
          document.documentElement.scrollTop || document.body.scrollTop
        );
      }

      // It is funny, but when we recreate style tag on `screenSize` change,
      // safari recalculate the height of the body, so player become look normal
      const css = `
        html, body {
          background-color: #000;
          overflow: hidden;

          max-width: 100vw;
        }
      `;

      styleTag.appendChild(document.createTextNode(css));
    }

    if (!isFullScreen && prevIsFullScreen) {
      setTimeout(() => {
        document.documentElement.scrollTop = document.body.scrollTop =
          scrollPosition || 0;
        // iOS need some time before we can scroll to previous position
      }, 200);
    }
  }, [isFullScreen, prevIsFullScreen, screenHeight, scrollPosition]);

  // On ios we need to beat all possible elements with higher z-index,
  // So we are adding z-index to all parent elements till body
  useEffect(() => {
    if (isFullScreen && elemRef.current) {
      const els: ParentNode[] = [];
      let currentEl = elemRef.current as ParentNode;
      while (currentEl.parentNode) {
        if (currentEl.parentNode === document.body) break;

        if ('classList' in currentEl.parentNode) {
          els.unshift(currentEl.parentNode);
        }

        currentEl = currentEl.parentNode;
      }

      els.forEach((el: HTMLElement) => {
        el.classList.add(styles.simulatedFullScreenFix);
      });

      return () => {
        els.forEach((el: HTMLElement) => {
          el.classList.remove(styles.simulatedFullScreenFix);
        });
      };
    }
  }, [isFullScreen]);

  return (
    <SimulatedFullScreenStyled
      ref={elemRef}
      enabled={isFullScreen}
      screenHeight={window.innerHeight}
      className={cx(styles.fullscreenSimulated, {
        [styles.enabled]: isFullScreen,
      })}
    >
      {children}
    </SimulatedFullScreenStyled>
  );
};

// If Player will break in fullscreen mode - we will remove
// style tags that are needed for simulated fullscreen mode,
// so it will not break the site
// @ts-ignore
if (typeof window !== 'undefined' && !window.simulatedFullscreenObserver) {
  // @ts-ignore
  window.simulatedFullscreenObserver = setInterval(() => {
    const styleTag = document.getElementById(fullScreenId);

    if (
      styleTag &&
      !document.querySelector(
        `.${styles.fullscreenSimulated}.${styles.enabled}`
      )
    ) {
      styleTag.childNodes.forEach((e) => e.remove());
    }
  }, 200);
}

export default WithFullScreen;
