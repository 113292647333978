import React, { forwardRef } from 'react';
import styled from 'styled-components';

const ratioToPaddingTop = (ratio?: number | 'auto') => {
  if (!ratio) return '0';

  if (ratio === 'auto') return '0';
  return 100 / ratio + '%';
};

const StyledRationDiv = styled.div<{ $ratio: number }>`
  aspect-ratio: ${(props) => props.$ratio};
  position: relative;
  margin: 0 auto;
  max-height: 100%;

  @supports not (aspect-ratio: ${(props) => props.$ratio}) {
    width: 100%;
    padding-top: ${(props) => ratioToPaddingTop(props.$ratio)};
  }
`;

const StyledAbsoluteDiv = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
`;

export const Ratio = forwardRef<
  HTMLDivElement,
  {
    children?: React.ReactNode;
    ratio: number;
    className?: string;
  }
>(({ ratio, children, className }, ref) => {
  return (
    <StyledRationDiv ref={ref} className={className} $ratio={ratio}>
      <StyledAbsoluteDiv>{children}</StyledAbsoluteDiv>
    </StyledRationDiv>
  );
});
