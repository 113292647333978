import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './styles/reset.skipScopify.sass';
import './utils/bootstrapInterceptors';

import {
  PlayerExternalAPI,
  PublicPlayerExternalAPI,
  toPublicApi,
  PlayerTimestamper,
  IPlayerWithVideo,
  IPlayerVideo,
  IPasswordProtection,
  IPasswordProtectedPlayerVideo,
} from '@voomly/player';
import { createGlobalStyle } from 'styled-components';
import { addScript } from '@voomly/utils';
import { toLogMessage } from '../Preloader/utils/logger';
import { OriginalView } from './OriginalView';

const EMBED_VIEW_FLOATING_MODAL_SCRIPT = 'embed-view-floating-modal';
const EMBED_VIEW_OPEN_IN_MODAL_SCRIPT = 'embed-view-open-in-modal';

const CSSColorVariables = createGlobalStyle`
  .voomly-embed {
    // NOTE: Voomly root is inline-flex element, it makes small gap under the player (about 4px)
    // since it's inline element, we can remove it by setting font-size to 0
    // it's safe because Voomly root has own "font-size: 16px" (libs/player/src/lib/components/VideoLayout/videoLayout.module.sass)
    font-size: 0 !important;
    --azure-500: #008EFF;
  }
`;

const layersDescriptors: {
  type: PlayerPresentationLayer;
  scriptName: string;
}[] = [
  {
    type: 'floatingModal',
    scriptName: EMBED_VIEW_FLOATING_MODAL_SCRIPT,
  },
  {
    type: 'openInModal',
    scriptName: EMBED_VIEW_OPEN_IN_MODAL_SCRIPT,
  },
];

const layersTypes = layersDescriptors.map((item) => item.type);

type MountView = React.NamedExoticComponent<{
  id: string;
  type: 'v' | 'f';
  ratio: string | '16:9' | 'auto';
  playerExternalAPI: PlayerExternalAPI;
  children?: React.ReactNode;
}>;

const attachPlayerStatusPairs: Map<HTMLElement, boolean> = new Map();

const attachedPlayerPairs: Map<HTMLElement, PublicPlayerExternalAPI> =
  new Map();
const attachedPlayerIdPairs: Map<string, VoomlyPlayerById[]> = new Map();

const getPlayerExternalAPIByContainer = (container: HTMLElement) =>
  attachedPlayerPairs.get(container);

const getAllPlayerExternalAPIsById = (id: string) =>
  attachedPlayerIdPairs.get(id);

const getPlayerExternalAPIById = (id: string) =>
  getAllPlayerExternalAPIsById(id)?.[0].api;

const savePlayerAPIForId = (
  id: string,
  type: 'v' | 'f' = 'v',
  element: HTMLDivElement,
  publicAPI: PublicPlayerExternalAPI
) => {
  const apiDescriptorsById = attachedPlayerIdPairs.get(id);

  // Remove prev API descriptors that's not connected
  const items = apiDescriptorsById?.filter((item) => {
    const isConnected = item.element.isConnected;
    if (!isConnected) {
      item.api.destroy();
    }

    return isConnected;
  });
  if (items) {
    attachedPlayerIdPairs.set(id, items);
  }

  const newAPIDescriptor = {
    id,
    type,
    api: publicAPI,
    element,
  };

  if (!apiDescriptorsById) {
    attachedPlayerIdPairs.set(id, [newAPIDescriptor]);
    return;
  }

  apiDescriptorsById.push(newAPIDescriptor);
};

const getPlayerExternalAPILoadStatusByContainer = (container: HTMLElement) =>
  attachPlayerStatusPairs.get(container);

const attachVoomlyPlayer = (
  element: HTMLDivElement,
  id: string,
  type: 'v' | 'f' = 'v',
  ratio: string | '16:9' | 'auto' = 'auto',
  presentation?: PlayerPresentationLayer
): PublicPlayerExternalAPI => {
  const loaders = element.getElementsByClassName('voomly-embed-loader');
  if (loaders.length) {
    for (const loader of loaders) {
      loader.remove();
    }
  }

  const renderedVoomlyPlayerAPI = getPlayerExternalAPIByContainer(element);

  // When SSR, player can be rendered on server,
  // so check if the player was rendered on client
  if (element.children.length > 0 && renderedVoomlyPlayerAPI) {
    return renderedVoomlyPlayerAPI;
  }

  const playerExternalAPI = new PlayerExternalAPI(id, {
    mountNode: element,
    isInIframe: window.location !== window.parent.location,
  });

  const publicAPI = toPublicApi(playerExternalAPI);

  void loadScripts((PresentationView?: MountView) => {
    const renderPlayer = ({
      onLoaded,
      onLoadedPasswordForm,
      dontPassPlayerExternalAPI,
      disableShortcuts,
    }: {
      onLoaded?: (playerConfig: IPlayerWithVideo, file: IPlayerVideo) => void;
      onLoadedPasswordForm?: (
        playerConfig: IPasswordProtection,
        file: IPasswordProtectedPlayerVideo
      ) => void;
      dontPassPlayerExternalAPI?: boolean;
      disableShortcuts?: boolean;
    }) => (
      <OriginalView
        id={id}
        type={type}
        ratio={ratio}
        onLoaded={onLoaded}
        onLoadedPasswordForm={onLoadedPasswordForm}
        disableShortcuts={disableShortcuts}
        playerExternalAPI={
          !dontPassPlayerExternalAPI ? playerExternalAPI : undefined
        }
      />
    );

    ReactDOM.render(
      <>
        <CSSColorVariables />
        {PresentationView ? (
          <PresentationView
            id={id}
            type={type}
            ratio={ratio}
            playerExternalAPI={playerExternalAPI}
          >
            {renderPlayer}
          </PresentationView>
        ) : (
          renderPlayer({})
        )}
      </>,
      element
    );

    // Goto proper timestamp from querystring
    const playerTimestamper = new PlayerTimestamper(id, publicAPI);
    playerTimestamper.run();

    // Set status that player scripts are loaded to call the API
    attachPlayerStatusPairs.set(element, true);

    // Call all methods that were called before the scripts loaded
    window.voomlyEmbedPlayerPreloader?.callPreviouslyCalledMethod(element);
  }, presentation);

  // Store public player API to access it later by id
  savePlayerAPIForId(id, type, element, publicAPI);

  // Store public player API to access it later by element
  attachedPlayerPairs.set(element, publicAPI);

  return publicAPI;
};

// eslint-disable-next-line
const loadScripts = async (
  onLoaded: (PresentationView?: MountView) => void,
  presentation?: PlayerPresentationLayer
) => {
  const promises: Promise<void>[] = [];

  const manifest = window.voomlyEmbedPlayerPreloader?.manifest;
  if (!manifest && process.env.NODE_ENV !== 'development') {
    throw Error('Manifest is not loaded');
  }

  const makeOnError = (reject) => (url: string, err: Error) => {
    console.error(toLogMessage(`Failed to load ${url}`), err);
    reject();
  };

  layersDescriptors.forEach(({ scriptName, type }) => {
    if (presentation !== type) {
      return;
    }

    const url = manifest ? manifest[`${scriptName}.js`] : '';

    promises.push(
      process.env.NODE_ENV === 'development'
        ? (async () => {
            if (presentation === 'floatingModal') {
              await import(
                /* webpackMode: "lazy" */
                /* webpackChunkName: "embed-view-floating-modal" */
                '@voomly/embed-view-floating-modal'
              );
            }
            if (presentation === 'openInModal') {
              await import(
                /* webpackMode: "lazy" */
                /* webpackChunkName: "embed-view-open-in-modal" */
                '@voomly/embed-view-open-in-modal'
              );
            }
          })()
        : new Promise((resolve, reject) => {
            addScript({
              id: scriptName,
              url,
              onLoad: () => resolve(),
              onError: makeOnError(reject),
            });
          })
    );
  });

  Promise.all(promises)
    .then(() => {
      onLoaded(
        presentation
          ? window.voomlyEmbedPlayerPresentationLayers[presentation]
          : undefined
      );
    })
    .catch((e) => {
      console.error(toLogMessage('Cannot load presentation layers'), e);
    });
};

const attachAllVoomlyPlayers = () => {
  const result = new Map<HTMLDivElement, PublicPlayerExternalAPI>();
  const apiInstances: PublicPlayerExternalAPI[] = [];
  const elements: HTMLDivElement[] = [];

  const embedVideos = document.getElementsByClassName('voomly-embed');
  if (embedVideos.length) {
    attachParticularVoomlyPlayers(
      Array.from(embedVideos),
      (element: HTMLDivElement, apiInstance: PublicPlayerExternalAPI) => {
        elements.push(element as HTMLDivElement);

        apiInstances.push(apiInstance);
      }
    );
  }

  apiInstances.forEach((instance, idx) => {
    result.set(elements[idx], instance);
  });

  return result;
};

const attachParticularVoomlyPlayers = (
  embedVideos: Element[],
  onAttach?: (
    element: HTMLDivElement,
    apiInstancePromise: PublicPlayerExternalAPI
  ) => void
) => {
  for (const element of embedVideos) {
    const id = element.getAttribute('data-id');
    const type = element.getAttribute('data-type') || 'v';
    const ratio = element.getAttribute('data-ratio') || 'auto';
    let presentation = element.getAttribute('data-presentation') as
      | PlayerPresentationLayer
      | undefined;
    if (type !== 'v' && type !== 'f') {
      console.error(`Wrong data-type!`, type);
      continue;
    }

    if (presentation && !layersTypes.includes(presentation)) {
      console.error(
        `Wrong data-presentation!`,
        presentation,
        'Fallback to original'
      );
      presentation = undefined;
    }

    if (!id) {
      console.error('Missing id!');
      continue;
    }

    const apiInstance = attachVoomlyPlayer(
      element as HTMLDivElement,
      id,
      type,
      ratio,
      presentation
    );

    onAttach?.(element as HTMLDivElement, apiInstance);
  }
};

let checkingNonRenderedVoomlyPlayersHandle:
  | ReturnType<typeof setInterval>
  | undefined = undefined;
const startCheckingNonRenderedVoomlyPlayers = () => {
  checkingNonRenderedVoomlyPlayersHandle = setInterval(() => {
    const embedVideosToAttach: Element[] = [];

    const embedVideos = document.getElementsByClassName('voomly-embed');
    for (const element of embedVideos) {
      if (!element.childNodes.length) {
        embedVideosToAttach.push(element);
      }
    }

    if (embedVideosToAttach.length) {
      attachParticularVoomlyPlayers(embedVideosToAttach);
    }
  }, 1000);
};
const stopCheckingNonRenderedVoomlyPlayers = () => {
  checkingNonRenderedVoomlyPlayersHandle &&
    clearInterval(checkingNonRenderedVoomlyPlayersHandle);
};

window.voomlyEmbedPlayerApp = {
  attach: attachVoomlyPlayer,
  attachAll: attachAllVoomlyPlayers,
  startAttachChecker: startCheckingNonRenderedVoomlyPlayers,
  stopAttachChecker: stopCheckingNonRenderedVoomlyPlayers,
  getPlayerExternalAPIByContainer,
  getPlayerExternalAPILoadStatusByContainer,
  getPlayerExternalAPIById,
  getAllPlayerExternalAPIsById,
};

window.voomlyReact = React;
window.voomlyReactDom = ReactDOM;

// Init layers object
window.voomlyEmbedPlayerPresentationLayers =
  window.voomlyEmbedPlayerPresentationLayers ?? {};
